


































































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class ReportsTyreHotelReplacements extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  filteringPanel = [1]
  filterLoading = false
  downloadReportLoading = false

  headers = []
  search = ''

  seasons = []
  suppliers = []
  productBrands = []
  speedIndexes = []
  loadIndexes = []
  productBrandsQualities = []

  filteringSupplier = null
  filteringSeason = null
  filteringProductBrand = null
  filteringTreadDepth = null
  filteringDefaultMinimumQuality = null
  filteringDefaultMinimumSpeedIndex = null
  filteringDefaultMinimumLoadIndex = null
  filteringIncludeNotMatchingReplacements = null

  treadDepthSign = 'less_or_equal'
  treadDepthIcon = 'far fa-less-than-equal'

  replacementsData = []

  tablePage = 1
  tableItemsPerPage = 25

  appendSitePrefix = appendSitePrefix

  get filterRequestData() {
    return {
      season: this.filteringSeason !== null && this.filteringSeason !== undefined ? this.filteringSeason.id : null,
      suppliers: this.filteringSupplier,
      product_brands: this.filteringProductBrand,
      tread_depth: this.filteringTreadDepth,
      tread_depth_sign: this.treadDepthSign,
      default_minimum_quality:
        this.filteringDefaultMinimumQuality !== null && this.filteringDefaultMinimumQuality !== undefined
          ? this.filteringDefaultMinimumQuality.id
          : null,
      default_minimum_speed_index:
        this.filteringDefaultMinimumSpeedIndex !== null && this.filteringDefaultMinimumSpeedIndex !== undefined
          ? this.filteringDefaultMinimumSpeedIndex.id
          : null,
      default_minimum_load_index:
        this.filteringDefaultMinimumLoadIndex !== null && this.filteringDefaultMinimumLoadIndex !== undefined
          ? this.filteringDefaultMinimumLoadIndex.id
          : null,
      include_not_matching_replacements: this.filteringIncludeNotMatchingReplacements,
    }
  }

  get excelFileName() {
    const date = moment().format('YYYY-MM-DD')
    const result = 'tyre_hotel_replacements'

    return result + '-' + date + '.xlsx'
  }

  created() {
    this.$axios.get('/v3/reports/tyre_hotel/replacements/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.suppliers = response.data.data.suppliers
      this.seasons = response.data.data.seasons
      this.productBrands = response.data.data.productBrands
      this.speedIndexes = response.data.data.speedIndexes
      this.loadIndexes = response.data.data.loadIndexes
      this.productBrandsQualities = response.data.data.productBrandsQualities
    })
  }

  changeTreadDepthSign() {
    if (this.treadDepthSign === 'less_or_equal') {
      this.treadDepthIcon = 'far fa-less-than'
      this.treadDepthSign = 'less'
    } else {
      this.treadDepthIcon = 'far fa-less-than-equal'
      this.treadDepthSign = 'less_or_equal'
    }
  }

  getReplacementsData() {
    if (this.$refs.form.validate()) {
      const self = this
      self.filterLoading = true

      this.$axios
        .post('/v3/reports/tyre_hotel/replacements/get_replacements_data', this.filterRequestData)
        .then((response) => {
          this.replacementsData = response.data.data.replacementsData
        })
        .finally(function () {
          self.filterLoading = false
        })
    }
  }

  downloadReport() {
    const self = this
    self.downloadReportLoading = true

    this.$axios
      .post('/v3/reports/tyre_hotel/replacements/download_report', this.filterRequestData)
      .then(function (response) {
        const blob = b64toBlob(response.data.data.binary, 'application/vnd.ms-excel')
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', self.excelFileName)
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(blob, self.excelFileName)
        }
      })
      .finally(function () {
        self.downloadReportLoading = false
      })
  }
}
